$(document).ready(function () {

    //Variables
    //Location Info
    LOCATION_NAME = 'taco express';
    ADDRESS = '4450 Centennial Blvd, Colorado Springs, CO 80907';
    ADDRESS_URL = 'https://goo.gl/maps/4WCZwCtaPwBZGzfB8';
    PHONE_NUMBER = '7195317831';
    PHONE_TEXT = '(719) 531-7831';
    HOURS_ST = '6:00 am - 12:00 am';
    //Social media
    FACEBOOK = 'https://www.facebook.com/TacoExpress4450';
    INSTAGRAM = '';
    YELP = 'https://www.yelp.com/biz/taco-express-colorado-springs-3?osq=Taco+Express';
    //3Rd Party Vendors
    DELIVERY1 = 'https://www.doordash.com/store/taco-express-colorado-springs-1110038/';
    DELIVERY2 = 'https://www.ubereats.com/store/taco-express-centennial/QqXOM7sRX6yJu-g3TN_nsA';
    //Google Reviews
    GOOGLE = 'https://www.google.com/search?q=taco+express+centennial&client=ubuntu-sn&hs=Kaj&channel=fs&sxsrf=AB5stBiq15HYJBGfJAERFOAw4c37L2t1YQ%3A1691016560464&ei=cN3KZJvpG5_IkPIP5PGUsAk&ved=0ahUKEwib4ZTLh7-AAxUfJEQIHeQ4BZYQ4dUDCA4&uact=5&oq=taco+express+centennial&gs_lp=Egxnd3Mtd2l6LXNlcnAiF3RhY28gZXhwcmVzcyBjZW50ZW5uaWFsMggQABiABBjLATIEEAAYHjIEEAAYHjIGEAAYCBgeSIkLUNsFWKwJcAB4AJABAJgB5QGgAcIIqgEFMC40LjK4AQPIAQD4AQHCAggQABiiBBiwA8ICBhAAGAcYHsICCBAAGAgYBxgewgIKEAAYCBgHGB4YCuIDBBgAIEGIBgGQBgU&sclient=gws-wiz-serp#lrd=0x8713502ed47c4601:0xdca3d03b454a2940,3,,,,';
    GOOGLE_DESK = 'https://www.google.com/search?q=taco%20express%20centennial&oq=taco%20express%20centennial&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBBzk1N2owajSoAgCwAgA&client=ms-android-attmexico-mx-revc&sourceid=chrome-mobile&ie=UTF-8&asid=canlbsc#lkt=LocalPoiReviews&trex=m_t:lcl_akp,rc_ludocids:15898780062532118848,rc_q:taco%2520express%2520centennial,ru_gwp:0%252C7,ru_q:taco%2520express%2520centennial,trex_id:EH884d';
    //About Us
    ABOUT_US = "Welcome to Taco Express, your ultimate destination for authentic and mouthwatering Mexican cuisine conveniently located at 4450 Centennial Blvd, Colorado Springs, CO 80907, Estados Unidos.  <br><br> At Taco Express, we pride ourselves on serving up a delectable array of Mexican delights that capture the essence of traditional flavors with a modern twist. Our menu is carefully crafted to satisfy your cravings with a variety of options that cater to every palate. ";
    ABOUT_US_SHORTY = "Welcome to Taco Express, your ultimate destination for authentic and mouthwatering Mexican cuisine conveniently located at 4450 Centennial Blvd, Colorado Springs, CO 80907, Estados Unidos. At Taco Express, we pride ourselves on...";
    //Iframe
    IFRAME = '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12420.906535574435!2d-104.8515449!3d38.8959324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8713502ed47c4601%3A0xdca3d03b454a2940!2sTaco%20Express!5e0!3m2!1ses!2smx!4v1691019654080!5m2!1ses!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> ';
    //About Us
    $('.text_about_home').each(function () {
        $(this).html(ABOUT_US_SHORTY);
    });
    $('.text_about').each(function () {
        $(this).html(ABOUT_US);
    });
    //Iframe
    $('.iframe').each(function () {
        $(this).html(IFRAME);
    });
    //Location Name
    $('.location_name').each(function () {
        $(this).text(LOCATION_NAME);
    });
    //Location Info
    $('.address_link').each(function () {
        $(this).click(function () {
            window.open(ADDRESS_URL, '_blank');
        });
    });
    $('.address_text').each(function () {
        $(this).text(ADDRESS);
    });
    //Phone
    $('.phone').each(function () {
        $(this).click(function () {
            window.location.href = 'tel:' + PHONE_NUMBER;
        });
    });
    $('.phone_number').each(function () {
        $(this).text(PHONE_TEXT);
    });
    //Email 
    $('.email').each(function () {
        $(this).click(function () {
            window.location.href = 'mailto:' + EMAIL_EMAIL;
        });
    });
    $('.email_text').each(function () {
        $(this).text(EMAIL_EMAIL);
    });
    //Google Reviews 
    $('.google_revs').each(function () {
        $(this).click(function () {
            window.open(GOOGLE, '_blank');
        });
    });
    $('.google_revsD').each(function () {
        $(this).click(function () {
            window.open(GOOGLE_DESK, '_blank');
        });
    });
    //Hours
    $('.h1').each(function () {
        $(this).text(HOURS_ST);
    });
    //Social Media
    $('.fb').each(function () {
        $(this).click(function () {
            window.open(FACEBOOK, '_blank');
        });
    });
    $('.yp').each(function () {
        $(this).click(function () {
            window.open(YELP, '_blank');
        });
    });
    //3Rd Party Vendors
    $('.deliver1').each(function () {
        $(this).click(function () {
            window.open(DELIVERY1, '_blank');
        });
    });
    $('.deliver2').each(function () {
        $(this).click(function () {
            window.open(DELIVERY2, '_blank');
        });
    });
});